<script>
   import Layout from "@/views/pages/super_admin/layout/main";
    import PageHeader from "@/components/static/page-header";
    import {mapState} from "vuex";
    import {restaurantMethods} from "@/state/helpers";

    import appConfig from "@/app.config";
    import { FormWizard, TabContent } from "vue-form-wizard";
    import "vue-form-wizard/dist/vue-form-wizard.min.css";
    import createRestaurantOwnerForm from "@/views/pages/super_admin/restaurant_owner/components/createRestaurantOwnerForm";
    import createRestaurantForm from "@/views/pages/super_admin/restaurant_owner/components/createRestaurantForm";
    
    export default {
        page: {
            title: "Dashboard",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,FormWizard,TabContent,
            createRestaurantOwnerForm,createRestaurantForm
        },
         computed: {
             ...mapState({
            restaurant: state => state.restaurant.restaurant
                }),
            },
        
        data() {
            return {
                title: "Restaurant",
                items: [
                    {
                        text: "Restaurant",
                    },
                    {
                        text: "Create",
                        active: true,
                    },
                ],
                
                loadingWizard: false,
                currentTabComponent: 'createRestaurantOwnerForm',
                tabs: [
                        {
                            id:1,
                            desc: 'Create Restaurant Owner',
                            component_name: 'createRestaurantOwnerForm',
                            icon: 'fa-file'
                        },
                        {
                            id:2,
                            desc: 'Create Restaurant',
                            component_name: 'createRestaurantForm',
                            icon: 'fa-file'
                        },
                ],
            };
        },
        methods: {

            ...restaurantMethods,
            
            onComplete: function(){
      
                  let self = this;
                if (self.loadingWizard) {
                return;
                }
                
                self.loadingWizard = true;
                
                self.$axios.post('/restaurant/store', {
                restaurant:self.restaurant
                    
                })
                .then(response => {
                    let data = response.data;

                    self.triggerSwal(data.message, "success");
                    self.resetState();
                    
                  self.$router.push("/restaurant-listing");
                  
                })
                .catch(error => {
                   
                    self.handleErrorResponse(error.response, "error");

                    
                })
                .finally(function () {
                    self.loadingWizard = false;
                });
             },

            setLoading: function(value) {
                this.loadingWizard = value
            },
            handleValidation: function(isValid, tabIndex){
                console.log('Tab: '+tabIndex+ ' valid: '+isValid)
            },
                validateRestaurant(component_name) {
                if(component_name=="createRestaurantOwnerForm"){
                   
                    if (this.$refs.createRestaurantOwnerForm[0].$v.restaurant.user.name.$invalid === true ||
                     this.$refs.createRestaurantOwnerForm[0].$v.restaurant.user.email.$invalid === true || 
                     this.$refs.createRestaurantOwnerForm[0].$v.restaurant.user.password.$invalid === true) 
                    {
                        return false;
                       }else {
                           return true
                       }
                }
                if(component_name=="createRestaurantForm"){
                    
                    if (this.$refs.createRestaurantForm[0].$v.restaurant.name.$invalid === true ||
                     this.$refs.createRestaurantForm[0].$v.restaurant.country.$invalid === true ) 
                    {
                        return false;
                       }else {
                           return true
                       }
                }
                
            },
       
  },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :items="items" :title="title"/>
        <div class="my-form">
        <div class="card">
        <div class="card-body">
        <div class="row">
         <form-wizard @on-complete="onComplete"
                @on-loading="setLoading"
                @on-validate="handleValidation"
                shape="circle"
               stepSize="xs"
                color="#f136a4">
                 
                <template v-for="tab in tabs">
                  
                <tab-content :key="tab.id" :title="tab.desc"
                            :before-change="() => validateRestaurant(tab.component_name)"
                            >
                        <component :is="tab.component_name" :ref="tab.component_name" ></component>
                </tab-content>
                </template>
            
      
      <div class="loader" v-if="loadingWizard"></div>
    </form-wizard>
        </div>
        </div>
        </div>
        </div>
    </Layout>
</template>
